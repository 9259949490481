
    import { mapState, mapGetters } from 'vuex';
    import moment from 'moment';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'HubHome',
        computed: {
            ...mapState({
                environments: (state: any) => state.environments
            }),
            ...mapGetters({
                demoEnvs: 'environments/demoEnvs'
            })
        },
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
        },
        methods: {
            formatDate(value: moment.MomentInput) {
                return moment(value).format('MMMM DD YYYY');
            },
            dnsLink(dns: string) {
                this.$router.push('/env/' + dns);
            },
            checkDemoDate(date: moment.MomentInput) {
                /* eslint-disable no-magic-numbers */

                return moment(date).isBefore(moment().subtract(90, 'days'));
            }
        }
    });
