import { Commit } from 'vuex';
import manager from '../../lib/manager';
import localStorageCache from '@/lib/localStorageCache';

type VERSIONS_KEYS = {
    cc: string;
    engine: string;
};

type State = {
    versions: {
        cc: string[];
        engine: string[];
    };
};

const VERSIONS_KEYS = {
    cc: 'ccVersions',
    engine: 'engineVersions'
};

const state: State = {
        versions: {
            cc: [],
            engine: []
        }
    },
    getters = {
        versions:
            (state: State) => (product: 'content controller' | 'engine') => {
                const productKey = product == 'engine' ? 'engine' : 'cc';
                return state.versions[productKey];
            }
    },
    actions = {
        getVersions(
            { commit, state }: { commit: Commit; state: State },
            product: 'content controller' | 'engine'
        ) {
            const productKey = product == 'engine' ? 'engine' : 'cc';
            const productVersions = state.versions[productKey];

            if (productVersions === undefined || productVersions.length == 0) {
                const storedVersions = localStorageCache.getItem(
                    VERSIONS_KEYS[productKey]
                );

                if (storedVersions) {
                    const payload = {
                        product: product,
                        productVersions: storedVersions
                    };
                    commit('setProductVersions', payload);
                    return;
                }

                // Only bother with the API request if we haven't already grabbed the version list.
                manager.getProductVersions(product).then((result: string[]) => {
                    const payload = {
                        product: product,
                        productVersions: result
                    };
                    commit('setProductVersions', payload);
                });
            } else {
                const payload = {
                    product: product,
                    productVersions: productVersions
                };
                commit('setProductVersions', payload);
            }
        }
    },
    mutations = {
        setProductVersions(
            state: State,
            payload: {
                product: 'content controller' | 'engine';
                productVersions: string[];
            }
        ) {
            const productKey = payload.product == 'engine' ? 'engine' : 'cc';

            const fifteenMinutes = 900000;
            localStorageCache.setItem(
                VERSIONS_KEYS[productKey],
                payload.productVersions,
                fifteenMinutes
            );

            state.versions[productKey] = payload.productVersions;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
