
    import moment from 'moment';
    import Constants from '@/constants';
    import Vue from 'vue';
    import { mapGetters } from 'vuex';
    export default Vue.extend({
        props: {
            dnsName: {
                type: String,
                default: ''
            },
            clientName: {
                type: String,
                default: ''
            },
            currentversion: {
                type: String,
                default: ''
            },
            product: {
                type: String,
                default: ''
            },
            envid: {
                type: Number
            }
        },
        data() {
            return {
                upgEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                upgradeVersion: '',
                responseFromApi: null,
                notes: '',
                /* eslint-disable no-magic-numbers */
                upgradedate: new Date().toISOString().slice(0, 10),
                upgradetime: new Date().toISOString().slice(11, 16),
                /* eslint-enable no-magic-numbers */
                currentstep: 1,
                showDatetime: false
            };
        },
        created() {
            this.getProductVersions();
        },
        computed: {
            ...mapGetters({
                versions: 'versions/versions'
            }),
            availableVersions: {
                get(): string[] {
                    const productVersions = this.versions(this.product);

                    if (
                        productVersions === undefined ||
                        productVersions.length == 0
                    ) {
                        // We haven't fetched the list of versions yet.
                        return ['Fetching versions. Please wait...'];
                    }

                    const validVersions = [];
                    const currentVersionPieces = this.currentversion.split('.');

                    productVersions.forEach((version: string) => {
                        let versionPieces = version.split('.');
                        let i = 0;
                        while (true) {
                            if (i >= versionPieces.length) {
                                // If we haven't reached a resolution before i exceeds the version
                                // length, the versions must be equal
                                break;
                            } else if (
                                Number(currentVersionPieces[i]) >
                                Number(versionPieces[i])
                            ) {
                                // The current version is newer
                                break;
                            } else if (
                                Number(currentVersionPieces[i]) <
                                Number(versionPieces[i])
                            ) {
                                // The current version is older
                                validVersions.push(version);
                                break;
                            } else {
                                // If nothing else worked, move to the next version piece
                                i++;
                            }
                        }
                    });

                    validVersions.push('other version (see notes)');
                    return validVersions;
                }
            },
            computedUpgradeTime: {
                get(): string {
                    if (this.showDatetime) {
                        return moment(this.upgradedate + ' ' + this.upgradetime)
                            .tz('GMT')
                            .format('YYYY-MM-DD HH:mm');
                    } else {
                        return new Date().toISOString();
                    }
                }
            },
            computedShouldAllowAction(): boolean {
                return (
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_SUPPORT) !=
                        -1 ||
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_ADMIN) != -1
                );
            }
        },
        methods: {
            getProductVersions() {
                this.$store.dispatch('versions/getVersions', this.product);
            },
            sendUpgradeEnvironmentRequest() {
                const envDetails = {
                    payload: {
                        action: 'upgrade',
                        clientName: this.clientName,
                        dnsName: this.dnsName,
                        newversion: this.upgradeVersion,
                        notes: this.notes,
                        product: this.product
                    },
                    requestedby: localStorage.getItem('user'),
                    scheduleddate: this.computedUpgradeTime,
                    envid: this.envid
                };

                this.$store
                    .dispatch('mhrequests/sendMhRequest', envDetails)
                    .then((response) => {
                        this.responseFromApi = response;
                        this.upgEnvDialog = false;
                        this.snackbar = true;
                        this.resetForm();
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.resetForm();
                this.upgEnvDialog = false;
            },
            resetForm() {
                this.upgradeVersion = '';
                this.notes = '';
                // eslint-disable-next-line no-magic-numbers
                this.upgradedate = new Date().toISOString().slice(0, 10);
                this.upgradetime = '';
                this.currentstep = 1;
            }
        }
    });
