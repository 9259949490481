
    import { mapState, mapGetters } from 'vuex';
    import contractdetail from '@/components/common/contractdetail.vue';
    import usercounts from '@/components/common/usercounts.vue';
    import utilities from '@/mixins/utilities';
    import editCustomerDetails from '@/components/MhCustomers/editCustomerDetails.vue';
    import newEnvForm from '../MhRequestForms/newEnvForm.vue';

    import Vue from 'vue';
import { Environment } from '@/store/types';
    export default Vue.extend({
        name: 'CustomerDetails',
        components: {
            newEnvForm,
            editCustomerDetails,
            contractdetail,
            usercounts
        },
        mixins: [utilities],
        computed: {
            ...mapState({
                environments: (state: any) => state.environments
            }),
            ...mapGetters({
                activeCustomer: 'customers/activeCustomer'
            }),
            customerEnvs(): Environment[] {
                return this.$store.getters['environments/getEnvsByCustomer'](
                    this.$route.params.id
                );
            },
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs').then(() => {
                this.$store.commit(
                    'customers/setActiveCustomer',
                    this.$route.params.id
                );
            });
            this.$store.dispatch('environments/getAllEnvironments');
        },
        data() {
            return {};
        },
        methods: {
            getEnvChipColor(type: string) {
                switch (type) {
                    case 'prod':
                        return 'green';
                    case 'dev':
                        return 'blue';
                    case 'demo':
                        return 'purple';
                    default:
                        console.error(
                            'Unknown environment type value: ' + type
                        );
                        return 'green';
                }
            },
            getColor(action: string) {
                switch (action) {
                    case 'newenv':
                        return 'green';
                    case 'upgrade':
                        return 'orange';
                    case 'delete':
                        return 'red';
                    default:
                        return 'primary';
                }
            },
            dnsLink(dns: string) {
                this.$router.push('/env/' + dns);
            },
            filteredEnvs(dns: string) {
                return this.environments.all.filter(
                    (e: { dns: string }) => e.dns === dns
                );
            },
            round(num: number) {
                const m = Number((Math.abs(num) * 100).toPrecision(15));
                // eslint-disable-next-line no-extra-parens
                return (Math.round(m) / 100) * Math.sign(num);
            }
        }
    });
