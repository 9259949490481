export default {
    getItem(key: string): object | null {
        const valueString = localStorage.getItem(key);

        if (valueString) {
            const stored = JSON.parse(valueString);

            if (stored.expiry >= new Date().getTime()) {
                return stored.value;
            } else {
                // The stored value is expired, delete it from cache
                localStorage.removeItem(key);
            }
        }

        return null;
    },
    setItem(key: string, value: object, expiry: number): void {
        let storedVal = null;

        const valueString = localStorage.getItem(key);
        if (valueString) {
            storedVal = JSON.parse(valueString).value;
        }

        // If this is a new value for the given key, store it and reset the expiry
        // Otherwise, do nothing
        if (storedVal != value) {
            localStorage.setItem(
                key,
                JSON.stringify({
                    value: value,
                    expiry: new Date().getTime() + expiry
                })
            );
        }
    }
};
