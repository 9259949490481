import Vue from 'vue';
import Vuex from 'vuex';
import environments from './modules/environments';
import aws from './modules/aws';
import versions from './modules/versions';
import mhrequests from './modules/mhrequests';
import customers from './modules/customers';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        environments,
        aws,
        versions,
        mhrequests,
        customers
    }
});
