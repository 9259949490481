const cognitoGroupName = {
    MH_ADMIN: 'mh-admins',
    MH_SUPPORT: 'mh-support',
    MH_ACCOUNTMANAGER: 'mh-accountmanager',
    MH_USER: 'mh-users'
};

const contractTermLength = {
    PROD: 12,
    DEMO: 3
};

const productId = {
    ENGINE: 1,
    CONTENT_CONTROLLER: 2
};

export default {
    cognitoGroupName,
    contractTermLength,
    productId
};
