
    import type { Contract } from '@/store/types';
    import moment from 'moment';
    import utilities from '@/mixins/utilities';
    import editContractDetails from '@/components/MhCustomers/editContractDetails.vue';

    import { defineComponent, PropType } from 'vue';
    export default defineComponent({
        name: 'ContractDetail',
        components: {
            editContractDetails
        },
        props: {
            dnsName: {
                type: String,
                required: true
            },
            contract: {
                type: Object as PropType<Contract>,
                required: true
            }
        },
        mixins: [utilities],
        data() {
            return {};
        },
        computed: {
            computedDateFormattedMomentjs() {
                return this.contract.start
                    ? moment(this.contract.start).format('MMMM Do YYYY')
                    : '';
            },
            computedExpireDateFormattedMomentjs() {
                const startDate = new Date(this.contract.start),
                    expireDate = startDate.setMonth(
                        +startDate.getMonth() + +this.contract.term_length
                    );

                return expireDate
                    ? moment(expireDate).format('MMMM Do YYYY')
                    : '';
            }
        }
    });
