var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.computedShouldAllowAction)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" New Environment ")]):_vm._e()]}}]),model:{value:(_vm.newEnvDialog),callback:function ($$v) {_vm.newEnvDialog=$$v},expression:"newEnvDialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("New Environment Request")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newEnvDialog = false}}},[_vm._v(" Close & Cancel ")])],1),_c('v-card-text',[_c('v-container',[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 1,"step":"1"}},[_vm._v(" Select Product to Install "),_c('small',[_vm._v(" Select Product, AWS Region, Deployment Stage, & DNS Name ")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mx-auto pa-5"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.products,"label":"Hosted Product*","required":""},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),(_vm.product)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.envTypes,"label":"Environment Type*","required":""},on:{"change":function($event){return _vm.updateModelFromComputed()}},model:{value:(_vm.envType),callback:function ($$v) {_vm.envType=$$v},expression:"envType"}})],1):_vm._e(),(_vm.product && _vm.envType)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.regions,"label":"AWS Region*","required":""},model:{value:(_vm.awsRegion),callback:function ($$v) {_vm.awsRegion=$$v},expression:"awsRegion"}})],1):_vm._e()],1),_c('v-row',[(
                                            _vm.product && _vm.envType && _vm.awsRegion
                                        )?_c('v-col',[_c('v-text-field',{attrs:{"label":"dns subdomain*","required":"","rules":[_vm.subDomainValidation]},model:{value:(_vm.dnsSubname),callback:function ($$v) {_vm.dnsSubname=$$v},expression:"dnsSubname"}}),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.computedDnsName)+" "),(
                                                    _vm.computedDnsNameAvailable ===
                                                    true
                                                )?_c('span',{staticClass:"green--text"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Available ")],1):_c('span',{staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" Not Available ")],1)]),_c('h5',{staticClass:"text-center"},[_vm._v(" ClientName - "+_vm._s(_vm.computedClientNameFromCustomerName)+" ")])],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.computedDnsNameAvailable)?_c('v-btn',{on:{"click":function($event){_vm.currentStep++}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 2,"step":"3"}},[_vm._v(" Notes "),_c('small',[_vm._v("Additional notes about this upgrade")])]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","label":"Notes","hint":"Additional notes about this upgrade"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.currentStep--}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.currentStep++;
                                            _vm.updateModelFromComputed();}}},[_vm._v(" Next ")])],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 3,"step":"4"}},[_vm._v(" Submit Request "),_c('small',[_vm._v("Environment Configuration Final Check")])]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_vm._v(" Creating a new "),_c('b',[_vm._v(_vm._s(_vm.envType))]),_c('b',[_vm._v(_vm._s(_vm.product))]),_vm._v(" environment at "),_c('b',[_vm._v(_vm._s(_vm.dnsName))])]),_c('v-card-text',[_vm._v(" Clientname will be "),_c('b',[_vm._v(_vm._s(_vm.clientName))])]),(_vm.notes)?_c('v-card-text',[_vm._v(" Additional Notes: "+_vm._s(_vm.notes)+" ")]):_vm._e(),_c('v-card-text',[_vm._v(" This process will notify you via Slack when it is completed or if there are errors. ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.currentStep--}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.sendNewEnvironmentRequest}},[_vm._v(" Create New Environment ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }