
    import { mapState, mapGetters } from 'vuex';
    import utilities from '@/mixins/utilities';

    import Vue from 'vue';

    export default Vue.extend({
        name: 'EnvList',
        mixins: [utilities],
        data() {
            return {
                filter: null,
                search: '',
                headers: [
                    {
                        text: 'DNS',
                        align: 'start',
                        value: 'dns'
                    },
                    { text: 'ClientName', value: 'clientname' },
                    { text: 'Product', value: 'product' },
                    { text: 'Type', value: 'type' },
                    { text: 'Region', value: 'region' },
                    { text: 'Version', value: 'version' },
                    { text: 'Courses', value: 'courses' },
                    { text: 'Users', value: 'userCounts.last_1_year_regular' }
                ]
            };
        },
        computed: {
            ...mapState({
                environments: (state: any) => state.environments
            }),
            ...mapGetters({
                engineEnvs: 'environments/engineEnvs',
                ccEnvs: 'environments/ccEnvs',
                demoEnvs: 'environments/demoEnvs',
                activeEnvs: 'environments/activeEnvs',
                inactiveEnvs: 'environments/inactiveEnvs',
                requestedEnvs: 'environments/requestedEnvs'
            })
        },
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
        },
        methods: {
            handleClick(value: { dns: any }) {
                this.$router.push('/env/' + value.dns);
            }
        }
    });
