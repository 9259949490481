import moment from 'moment-timezone';

export default {
    methods: {
        relativeDate(date: moment.MomentInput): string {
            return moment.utc(date).local().fromNow();
        },
        localTime(date: moment.MomentInput): moment.Moment {
            return moment.utc(date).local();
        },
        valueOrUnknown(value: any): any {
            return value === null ? 'Unknown' : value;
        },
        valueOrZero(value: any): any {
            return value === null ? 0 : value;
        },
        yearMonthDay(datetime: moment.MomentInput): string {
            return moment(datetime).format('YYYY-MM-DD');
        }
    }
};
