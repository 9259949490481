
    import Constants from '@/constants';
    import Vue from 'vue';
    import { mapState } from 'vuex';
    export default Vue.extend({
        props: {
            // eslint-disable-next-line vue/require-default-prop
            customer: {
                type: Object
            }
        },
        data() {
            return {
                newEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                clientName: '',
                dnsName: '',
                awsRegion: '',
                envType: '',
                productId: 1,
                product: '',
                notes: '',
                customerId: '',
                responseFromApi: null,
                currentStep: 1,
                dnsSubname: ''
            };
        },
        computed: {
            ...mapState({
                environments: (state: any) => state.environments.all,
                regions: (state: any) => state.aws.regions,
                envTypes: (state: any) => state.aws.envtypes,
                products: (state: any) => state.aws.products
            }),
            computedDnsName(): string {
                let fqdn = '';

                if (this.product === 'engine') {
                    fqdn = this.dnsSubname + '.engine.scorm.com';
                }
                if (this.product === 'contentcontroller') {
                    fqdn = this.dnsSubname + '.contentcontroller.com';
                }

                return fqdn;
            },
            computedDnsNameAvailable(): boolean {
                let fqdnAvailable = true;

                if (this.dnsSubname !== '') {
                    for (const env of this.environments) {
                        if (env.dns === this.computedDnsName) {
                            fqdnAvailable = false;
                        }
                    }
                } else {
                    fqdnAvailable = false;
                }

                return fqdnAvailable;
            },
            computedClientNameFromCustomerName(): string {
                let clientNameSuffix = '';

                switch (this.envType) {
                    case 'demo':
                        clientNameSuffix = 'dmo';
                        break;
                    case 'dev':
                        clientNameSuffix = 'dev';
                        break;
                    case 'prod':
                        clientNameSuffix = 'prd';
                        break;
                    default:
                        clientNameSuffix = 'unk';
                        break;
                }

                // eslint-disable-next-line no-magic-numbers
                const computedClientname =
                    this.customer.name
                        .replaceAll(' ', '')
                        .substr(0, 9)
                        .toLowerCase() + clientNameSuffix;

                return computedClientname;
            },
            computedShouldAllowAction(): boolean {
                return (
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_SUPPORT) !=
                        -1 ||
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_ADMIN) != -1
                );
            },
            subDomainValidation(): string | boolean {
                if (
                    this.envType == 'demo' &&
                    !this.dnsSubname.endsWith('-demo')
                ) {
                    return "POCs must end with '-demo'";
                } else {
                    return true;
                }
            },
            getProductIdFromName(): number {
                return this.product === 'engine'
                    ? Constants.productId.ENGINE
                    : Constants.productId.CONTENT_CONTROLLER;
            }
        },
        methods: {
            sendNewEnvironmentRequest() {
                this.updateModelFromComputed();

                const envDetails = {
                    payload: {
                        action: 'newenv',
                        requested_by_slack: localStorage.getItem('slack'),
                        clientName: this.clientName,
                        dnsName: this.dnsName,
                        awsRegion: this.awsRegion,
                        privatebranch: 'master',
                        envType: this.envType,
                        appversion: 'latest',
                        product: this.product,
                        notes: this.notes
                    },
                    requestedby: localStorage.getItem('user')
                };

                this.$store
                    .dispatch('mhrequests/sendMhRequest', envDetails)
                    .then(() => {
                        const newEnvRecord = {
                            envType: this.envType,
                            product: this.product,
                            productId: this.productId,
                            clientName: this.clientName,
                            dnsName: this.dnsName,
                            awsRegion: this.awsRegion,
                            clientId: this.customer.id,
                            termLength:
                                this.envType === 'prod'
                                    ? Constants.contractTermLength.PROD
                                    : Constants.contractTermLength.DEMO
                        };

                        this.$store
                            .dispatch(
                                'environments/createNewEnvRecord',
                                newEnvRecord
                            )
                            .then((result) => {
                                this.responseFromApi = result;
                                this.newEnvDialog = false;
                                this.snackbar = true;
                            });
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            updateModelFromComputed() {
                this.dnsSubname =
                    this.customer.name.replaceAll(' ', '').toLowerCase() +
                    '-' +
                    this.envType;
                this.dnsName = this.computedDnsName;
                this.productId = this.getProductIdFromName;
                this.clientName = this.computedClientNameFromCustomerName;
            }
        }
    });
