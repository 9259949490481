
    import Constants from '@/constants';
    import Vue from 'vue';
    export default Vue.extend({
        props: {
            dnsName: {
                type: String,
                default: ''
            },
            clientName: {
                type: String,
                default: ''
            },
            currentversion: {
                type: String,
                default: ''
            },
            product: {
                type: String,
                default: ''
            },
            envid: {
                type: Number
            }
        },
        data() {
            return {
                modEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                responseFromApi: null,
                notes: '',
                currentstep: 1
            };
        },
        computed: {
            computedShouldAllowAction(): boolean {
                return (
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_SUPPORT) !=
                        -1 ||
                    localStorage
                        .getItem('user-groups')
                        ?.indexOf(Constants.cognitoGroupName.MH_ADMIN) != -1
                );
            }
        },
        methods: {
            sendEnvironmentModificationRequest() {
                const envDetails = {
                    payload: {
                        action: 'modify',
                        clientName: this.clientName,
                        dnsName: this.dnsName,
                        notes: this.notes,
                        product: this.product
                    },
                    requestedby: localStorage.getItem('user'),
                    envid: this.envid
                };

                this.$store
                    .dispatch('mhrequests/sendMhRequest', envDetails)
                    .then((response) => {
                        this.responseFromApi = response;
                        this.modEnvDialog = false;
                        this.snackbar = true;
                        this.resetForm();
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.resetForm();
                this.modEnvDialog = false;
            },
            resetForm() {
                this.notes = '';
                this.currentstep = 1;
            }
        }
    });
