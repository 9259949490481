
    import { defineComponent } from 'vue';
    import type { Environment, Contract, UserCounts } from '@/store/types';
    import utilities from '@/mixins/utilities';

    export default defineComponent({
        name: 'UserCounts',
        props: {
            dnsName: {
                type: String,
                required: true
            }
        },
        mixins: [utilities],
        data() {
            return {
                headers: [
                    {
                        text: 'Term',
                        value: 'term',
                        variant: 'active'
                    },
                    {
                        text: 'Regular',
                        value: 'regular'
                    },
                    {
                        text: 'Dispatch',
                        value: 'dispatch'
                    },
                    {
                        text: 'Total',
                        value: 'total'
                    }
                ]
            };
        },
        computed: {
            activeEnv(): Environment {
                return this.$store.getters['environments/getEnvByDns'](
                    this.dnsName
                );
            },
            items() {
                if (this.activeEnv?.userCounts) {
                    return [
                        this.userCountItem(
                            'Last 30 Days',
                            'last_30',
                            this.activeEnv.userCounts
                        ),
                        this.userCountItem(
                            'Last 90 Days',
                            'last_90',
                            this.activeEnv.userCounts
                        ),
                        this.userCountItem(
                            'Last 12 Months',
                            'last_1_year',
                            this.activeEnv.userCounts
                        ),
                        this.userCountItem(
                            this.yearOverYearString,
                            'yoy',
                            this.activeEnv.userCounts
                        ),
                        this.userCountItem(
                            this.contractString,
                            'term_to_date',
                            this.activeEnv.userCounts
                        )
                    ];
                } else {
                    return [];
                }
            },
            contractString(): string {
                if (
                    this.getContracts().start === null ||
                    this.getContracts().term_length === null
                ) {
                    return 'Unknown';
                }
                const start = new Date(this.getContracts().start),
                    end = new Date(this.getContracts().start);

                end.setMonth(
                    start.getMonth() + this.getContracts().term_length
                );

                return `${this.valueOrUnknown(
                    this.yearMonthDay(start)
                )} to ${this.valueOrUnknown(this.yearMonthDay(end))}`;
            },
            yearOverYearString(): string {
                const start = new Date(this.getContracts().start),
                    today = new Date(),
                    yoy = new Date(
                        today.getFullYear(),
                        start.getMonth(),
                        start.getDate()
                    );

                let yoyString = '';

                if (yoy > today) {
                    yoy.setFullYear(yoy.getFullYear() - 1);
                }

                if (this.getContracts().start === null) {
                    yoyString = 'Unknown';
                } else {
                    yoyString = `${this.valueOrUnknown(
                        this.yearMonthDay(yoy)
                    )} to ${this.valueOrUnknown(this.yearMonthDay(today))}`;
                }

                return yoyString;
            }
        },
        methods: {
            userCountItem(
                name: string,
                rangeTerm:
                    | 'last_30'
                    | 'last_90'
                    | 'last_1_year'
                    | 'yoy'
                    | 'term_to_date',
                userCounts: UserCounts
            ) {
                return {
                    term: name,
                    regular: this.valueOrZero(
                        userCounts[`${rangeTerm}_regular`]
                    ),
                    dispatch: this.valueOrZero(
                        userCounts[`${rangeTerm}_dispatch`]
                    ),
                    total: this.valueOrZero(userCounts[`${rangeTerm}_regular`]) +
                            this.valueOrZero(userCounts[`${rangeTerm}_dispatch`])

                };
            },
            getContracts(): Contract {
                if (typeof this.activeEnv.contracts !== 'undefined') {
                    return this.activeEnv.contracts;
                } else {
                    return { term_length: 0, start: '', user_limit: 0 };
                }
            },
            getUserCounts(): UserCounts | undefined {
                if (typeof this.activeEnv.userCounts !== 'undefined') {
                    return this.activeEnv.userCounts;
                }
            },
            getTermYears() {
                let amount = 0;
                if (typeof this.getContracts() !== 'undefined') {
                    amount = this.getContracts().term_length;
                }

                return `${amount} ${amount > 1 ? 'years' : 'year'}`;
            },
            getUpdate() {
                return this.activeEnv.updateDt;
            }
        }
    });
