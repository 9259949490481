
    import { mapGetters } from 'vuex';

    import utilities from '@/mixins/utilities';
    import moment from 'moment';
    import createCustomer from '@/components/MhCustomers/createCustomer.vue';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'CustomerList',
        components: { createCustomer },
        mixins: [utilities],
        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Account Manager', value: 'am_name' },
                    { text: 'Environments', value: 'env_count'}
                ]
            };
        },
        computed: {
            ...mapGetters({
                customers: 'customers/allCustomers'
            })
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs');
        },
        methods: {
            getEnvChipColor(type: string): string {
                switch (type) {
                    case 'prod':
                        return 'green';
                    case 'dev':
                        return 'blue';
                    case 'demo':
                        return 'purple';
                    default:
                        alert('Unknown environment type value: ' + type);
                        return 'green';
                }
            },
            handleClick(value: { id: string }): void {
                this.$router.push('/customer/' + value.id);
            },
            getColor(action: string): string {
                switch (action) {
                    case 'newenv':
                        return 'green';
                    case 'upgrade':
                        return 'orange';
                    case 'delete':
                        return 'red';
                    default:
                        return 'primary';
                }
            },
            formatDate(value: string) {
                return moment(value).format('MMMM DD YYYY');
            },
            dnsLink(dns: string) {
                this.$router.push('/env/' + dns);
            }
        }
    });
