
    import { mapGetters } from 'vuex';

    import utilities from '@/mixins/utilities';

    import usercounts from '../common/usercounts.vue';
    import contractdetail from '@/components/common/contractdetail.vue';
    import envConfig from './envConfig.vue';
    import envEvents from './envEvents.vue';
    import upgradeEnvForm from '../MhRequestForms/upgradeEnvForm.vue';
    import deleteEnvForm from '../MhRequestForms/deleteEnvForm.vue';
    import genericEnvModForm from '../MhRequestForms/genericEnvModForm.vue';

    import Vue from 'vue';
    export default Vue.extend({
        name: 'EnvDetails',
        components: {
            contractdetail,
            usercounts,
            envConfig,
            envEvents,
            upgradeEnvForm,
            deleteEnvForm,
            genericEnvModForm
        },
        mixins: [utilities],
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            }),
            awsRdsLink: {
                get() {
                    return (
                        'https://us-east-1.console.aws.amazon.com/rds/home?region=' +
                        this.activeEnv.region +
                        '#database:id=mh-' +
                        this.activeEnv.clientname +
                        '-rds-masterdb;is-cluster=false'
                    );
                }
            },
            awsElbLink: {
                get() {
                    return (
                        'https://us-east-1.console.aws.amazon.com/ec2/v2/home?region=' +
                        this.activeEnv.region +
                        '#LoadBalancers:search=' +
                        this.activeEnv.clientname +
                        ';sort=loadBalancerName'
                    );
                }
            },
            awsS3Link: {
                get() {
                    return (
                        'https://s3.console.aws.amazon.com/s3/buckets/' +
                        this.activeEnv.clientname +
                        '-cc-content-logs?region=' +
                        this.activeEnv.region +
                        '&tab=objects'
                    );
                }
            },
            githubAnsibleChangeLog: {
                get() {
                    return (
                        'https://github.com/RusticiSoftware/cc-deployment-private/commits/master/host_vars/' +
                        this.activeEnv.dns +
                        '.yml'
                    );
                }
            },
            githubTerraformChangeLog: {
                get() {
                    return (
                        'https://github.com/RusticiSoftware/cc-deployment-private/commits/master/terraform/' +
                        this.activeEnv.dns +
                        '/terraform.tfvars.json'
                    );
                }
            }
        },
        created() {
            this.$store.commit(
                'environments/setActiveEnv',
                this.$route.params.id
            );
            this.$store.dispatch(
                'environments/getEnvironmentConfig',
                this.$route.params.id
            );
            this.$store.dispatch(
                'environments/getEnvironmentEvents',
                this.$route.params.id
            );
        },
        methods: {
            customerLink(id: string) {
                this.$router.push('/customer/' + id);
            }
        }
    });
