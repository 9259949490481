
    import type { Contract } from '@/store/types';
    import { mapState } from 'vuex';
    import moment from 'moment';
    import { defineComponent, PropType } from 'vue';

    export default defineComponent({
        props: {
            dnsName: {
                type: String,
                required: true
            },
            contract: {
                type: Object as PropType<Contract>,
                required: true
            }
        },
        data() {
            return {
                UpdateDialog: false,
                snackbar: false,
                snackbarText: 'Contract details have been updated.',
                contractToSave: { term_length: 0, user_limit: 0, start: '' },
                calmenu: false,
                // eslint-disable-next-line no-mixed-operators, no-magic-numbers
                date: new Date().toISOString().substr(0, 10),
                start: '',
                termLength: 0,
                userLimit: 0
            };
        },
        computed: {
            ...mapState({
                accountmanagers: (state: any) =>
                    state.customers.allAccountManagers
            }),
            computedDateFormattedMomentjs(): string {
                return this.start
                    ? moment(this.start).format('MMMM Do YYYY')
                    : '';
            }
        },
        created() {
            this.contractToSave = this.contract;
            this.termLength = this.contract.term_length;
            this.userLimit = this.contract.user_limit;
            this.start = this.contract.start;
        },
        methods: {
            sendContractUpdateRequest() {
                // make sure the since date is in the correct format
                this.contractToSave.start = moment(this.start).format(
                    'YYYY-MM-DD'
                );
                /* eslint-disable camelcase */
                this.contractToSave.term_length = this.termLength;
                this.contractToSave.user_limit = this.userLimit;
                this.$store
                    .dispatch(
                        'customers/updateLicenseDetail',
                        this.contractToSave
                    )
                    .then(() => {
                        return this.$store.dispatch(
                            'environments/getEnvironment',
                            this.dnsName
                        );
                    })
                    .then(() => {
                        this.UpdateDialog = false;
                        this.snackbar = true;
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.userLimit = this.contract.user_limit;
                this.termLength = this.contract.term_length;
                this.start = this.contract.start;
                this.UpdateDialog = false;
            },
            saveDateSelection() {
                this.start = this.date;
                this.calmenu = false;
            }
        }
    });
