import { Commit } from 'vuex';
import manager from '../../lib/manager';
import Constants from '@/constants';
import type {
    AccountManager,
    AwsCustomerCost,
    AwsCustomerCostDbRecord,
    AwsServiceCostDbRecord,
    AwsServiceCost,
    Customer,
    ProductLicense,
    AwsYearlyCustomerCosts
} from '../types';

type State = {
    active: string;
    allAccountManagers: AccountManager[];
    allCustomers: Customer[];
    yearlyCostDataByService: AwsServiceCost[];
    yearlyCostDataByServiceForEngine: AwsServiceCost[];
    yearlyCostDataByServiceForCc: AwsServiceCost[];
    yearlyCostDataByCustomer: AwsYearlyCustomerCosts;
};

const state: State = {
        active: '',
        allAccountManagers: [],
        allCustomers: [],
        yearlyCostDataByService: [],
        yearlyCostDataByServiceForEngine: [],
        yearlyCostDataByServiceForCc: [],
        yearlyCostDataByCustomer: {}
    },
    getters = {
        allCustomers: (state: State) => {
            return state.allCustomers;
        },
        getCustomerById: (state: State, getters: any) => (id: string) => {
            return getters.allCustomers.find(
                (customer: Customer) => customer.id.toString() === id
            );
        },
        activeCustomer: (
            state: State,
            getters: { getCustomerById: (id: string) => Customer }
        ) => {
            return getters.getCustomerById(state.active);
        }
    },
    actions = {
        getCustomersAndAMs({ commit }: { commit: Commit }) {
            if (!state.allCustomers.length) {
                const allCustomers = manager
                    .getAllCustomers()
                    .then((result: Customer[]) => {
                        commit('setAllCustomers', result);
                    });

                const allAccountManagers = manager
                    .getAllAccountManagers()
                    .then((result: AccountManager[]) => {
                        commit('setAllAccountManagers', result);
                    });
                return Promise.all([allCustomers, allAccountManagers]);
            } else {
                return Promise.resolve();
            }
        },
        getYearlyAwsCostsByService({ commit }: { commit: Commit }) {
            return manager
                .getYearlyAwsCostsByService()
                .then((result: AwsServiceCostDbRecord[]) => {
                    commit(
                        'setYearlyAwsCostsByService',
                        mapToAwsServiceCost(result)
                    );
                });
        },

        getYearlyCostDataByServiceForEngine({ commit }: { commit: Commit }) {
            return manager
                .getYearlyAwsCostsByServiceByProduct(
                    String(Constants.productId.ENGINE)
                )
                .then((result: AwsServiceCostDbRecord[]) => {
                    commit(
                        'setYearlyAwsCostsByServiceForEngine',
                        mapToAwsServiceCost(result)
                    );
                });
        },
        getYearlyCostDataByServiceForCc({ commit }: { commit: Commit }) {
            return manager
                .getYearlyAwsCostsByServiceByProduct(
                    String(Constants.productId.CONTENT_CONTROLLER)
                )
                .then((result: AwsServiceCostDbRecord[]) => {
                    commit(
                        'setYearlyAwsCostsByServiceForCc',
                        mapToAwsServiceCost(result)
                    );
                });
        },
        getYearlyAwsCostsByCustomer(
            { commit }: { commit: Commit },
            year: string
        ) {
            return manager
                .getYearlyAwsCostsByCustomer(year)
                .then((result: AwsCustomerCostDbRecord[]) => {
                    const yearlyCosts = {
                        year,
                        data: mapToAwsCustomerCost(result)
                    };
                    commit('setYearlyCostDataByCustomer', yearlyCosts);
                });
        },
        createCustomer({commit}: { commit: Commit }, customer: Customer) {
            return manager.createCustomer(customer).then(() => {
                manager.getAllCustomers()
                .then((result: Customer[]) => {
                    commit('setAllCustomers', result);
                });
            });
        },
        updateCustomerDetail({}: { commit: Commit }, customer: Customer) {
            return manager.updateCustomerDetail(customer.id, customer);
        },
        updateLicenseDetail({}: { commit: Commit }, license: ProductLicense) {
            return manager.updateLicenseDetail(license);
        }
    },
    mutations = {
        setActiveCustomer(state: State, id: string) {
            state.active = id;
        },

        setYearlyAwsCostsByService(state: State, result: AwsServiceCost[]) {
            state.yearlyCostDataByService = result;
        },

        setYearlyAwsCostsByServiceForEngine(
            state: State,
            result: AwsServiceCost[]
        ) {
            state.yearlyCostDataByServiceForEngine = result;
        },

        setYearlyAwsCostsByServiceForCc(
            state: State,
            result: AwsServiceCost[]
        ) {
            state.yearlyCostDataByServiceForCc = result;
        },

        setYearlyCostDataByCustomer(
            state: State,
            result: { year: string; data: AwsCustomerCost[] }
        ) {
            state.yearlyCostDataByCustomer[result.year] = result.data;
        },

        setAllAccountManagers(state: State, result: AccountManager[]) {
            state.allAccountManagers = result;
        },

        setAllCustomers(state: State, result: Customer[]) {
            state.allCustomers = result;
        },

        addNewCustomer(state: State, newCustomer: Customer) {
            state.allCustomers.push(newCustomer);
        }
    };

function mapToAwsServiceCost(
    records: AwsServiceCostDbRecord[]
): AwsServiceCost[] {
    return records.map((record) => ({
        awsService: record.aws_service,
        annualCost: record.annual_cost,
        year: record.year
    }));
}

function mapToAwsCustomerCost(
    records: AwsCustomerCostDbRecord[]
): AwsCustomerCost[] {
    return records.map((record) => ({
        customerId: record.customer_id,
        annualCost: record.annual_cost,
        company: record.company
    }));
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
