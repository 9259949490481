import Vue from 'vue';
import { Commit } from 'vuex';
import manager from '../../lib/manager';
import type {
    Environment,
    EngineConfig,
    EventRecord,
    newEnvRecord
} from '../types';

type State = {
    all: Environment[];
    active: string;
    fields: object[];
    sortBy: string;
    initialized: boolean;
};

const state: State = {
        all: [] as Environment[],
        active: '',
        fields: [
            {
                key: 'dns',
                sortable: true,
                label: 'DNS'
            },
            {
                key: 'product',
                sortable: true,
                label: 'Product'
            },
            {
                key: 'type',
                sortable: true,
                label: 'Type'
            },
            {
                key: 'region',
                sortable: true,
                label: 'Region'
            },
            {
                key: 'version',
                sortable: true,
                label: 'Version'
            },
            {
                key: 'courses',
                sortable: true,
                label: 'Courses'
            },
            {
                key: 'lastYearTotalUsers',
                sortable: true,
                label: 'Users',
                thAttr: { title: 'Trailing 12 Months' }
            },
            {
                key: 'status',
                sortable: true,
                label: 'Status'
            },
            {
                key: 'updateDt',
                sortable: true,
                label: 'Updated'
            },
            {
                key: 'companyId',
                sortable: false,
                label: 'CompanyId'
            },
            {
                key: 'companyName',
                sortable: false,
                label: 'CompanyName'
            },
            {
                key: 'amName',
                sortable: false,
                label: 'AmName'
            },
            {
                key: 'amId',
                sortable: false,
                label: 'AmId'
            },
            {
                key: 'contractId',
                sortable: false,
                label: 'ContractId'
            }
        ],
        sortBy: 'DNS',
        initialized: false
    },
    getters = {
        getEnvByDns: (state: State) => (dns: string) =>
            state.all.find((env: { dns: string }) => env.dns === dns),
        activeEnv: (
            state: State,
            getters: { getEnvByDns: (dns: string) => Environment }
        ) => getters.getEnvByDns(state.active),
        engineEnvs: (state: State) =>
            state.all.filter(
                (env: any) =>
                    env.status === 'running' && env.product === 'engine'
            ),
        ccEnvs: (state: State) =>
            state.all.filter(
                (env: any) =>
                    env.status === 'running' &&
                    env.product === 'content controller'
            ),
        demoEnvs: (state: State) =>
            state.all.filter(
                (env: any) => env.status === 'running' && env.type === 'demo'
            ),
        activeEnvs: (state: State) =>
            state.all.filter((env: any) => env.status === 'running'),
        inactiveEnvs: (state: State) =>
            state.all.filter((env: any) => env.status === 'stopped'),
        requestedEnvs: (state: State) =>
            state.all.filter((env: any) => env.status === 'requested'),
        getEnvsByCustomer: (state: State) => (companyId: string) =>
            state.all.filter((env: Environment) => env.companyId == companyId)
    },
    actions = {
        getAllEnvironments({ commit }: { commit: Commit }) {
            if (!state.initialized) {
                return manager
                    .getEnvList()
                    .then((environments: Array<Environment>) => {
                        commit('setEnvList', environments);
                        commit('setInitialized', true);
                    });
            } else {
                return Promise.resolve();
            }
        },

        getEnvironment({ commit }: { commit: Commit }, dns: string) {
            return manager.getEnv(dns).then((environment: Environment) => {
                commit('updateEnv', environment);
            });
        },

        createNewEnvRecord({}: { commit: Commit }, envDetails: newEnvRecord) {
            return manager.createNewEnvRecord(envDetails);
        },

        getEnvironmentConfig({ commit }: { commit: Commit }, dns: string) {
            return manager.getEnvConfig(dns).then((config: EngineConfig) => {
                commit('addConfig', config);
            });
        },

        getEnvironmentEvents({ commit }: { commit: Commit }, dns: string) {
            return manager
                .getAllEventsForEnv(dns)
                .then((events: EventRecord[]) => {
                    commit('addEvents', events);
                });
        }
    },
    mutations = {
        setEnvList(state: State, list: Environment[]) {
            state.all = list;
        },

        updateEnv(state: State, environment: Environment) {
            for (let i = 0; i < state.all.length; i++) {
                if (state.all[i].dns == environment.dns) {
                    Vue.set(state.all, i, environment);
                    return;
                }
            }

            state.all.push(environment);
        },

        addConfig(state: State, config: EngineConfig) {
            const env = state.all.find((env) => env.dns === state.active);
            if (typeof env !== 'undefined') {
                env.config = config;
                Vue.set(state.all, state.all.indexOf(env), env);
            }
        },

        addEvents(state: State, events: EventRecord[]) {
            const env = state.all.find((env) => env.dns === state.active);
            if (typeof env !== 'undefined') {
                env.events = events;
            }
        },

        setActiveEnv(state: { active: string }, dns: string) {
            state.active = dns;
        },

        setInitialized(state: { initialized: boolean }, initialized: boolean) {
            state.initialized = initialized;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
