import { API, Auth } from 'aws-amplify';
import type {
    AccountManager,
    AwsCustomerCostDbRecord,
    AwsServiceCostDbRecord,
    Customer,
    EngineConfig,
    Environment,
    MhRequest,
    ProductLicense,
    EventRecord,
    newEnvRecord
} from '@/store/types';

async function apiRequest(
    path: string,
    method: 'get' | 'put' | 'post' | 'del' = 'get',
    body: any = undefined
) {
    const request = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            'Content-type': 'application/json'
        },
        body
    };

    return API[method]('mhapi', path, request).catch((error) => {
        console.log(error);
    });
}

export default {
    async getEnvList(): Promise<Environment[]> {
        return (await apiRequest('/envs')) as Environment[];
    },

    async getEnv(environment: string): Promise<Environment> {
        return (await apiRequest(`/envs/${environment}`))[0] as Environment;
    },

    async getEnvConfig(dns: string): Promise<EngineConfig> {
        return (await apiRequest(`/envs/${dns}/config`)) as EngineConfig;
    },

    async sendMhRequest(envDetails: any) {
        return await apiRequest('/mhrequests', 'post', envDetails);
    },

    async createNewEnvRecord(envDetails: newEnvRecord) {
        return await apiRequest('/envs/newenv', 'post', envDetails);
    },

    async getAllRequests(): Promise<MhRequest[]> {
        return await apiRequest('/mhrequests');
    },

    async updateRequest(id: string, status: string, notes: string) {
        const body = {
            status: status,
            notes: notes
        };
        return await apiRequest(`/mhrequests/${id}`, 'put', body);
    },

    async getYearlyAwsCostsByCustomer(
        year: string
    ): Promise<AwsCustomerCostDbRecord[]> {
        return await apiRequest(
            `/customers/reporting/totalcostsbyyear/${year}`
        );
    },

    async getYearlyAwsCostsByService(): Promise<AwsServiceCostDbRecord[]> {
        return await apiRequest('/customers/reporting/servicecostsbyyear');
    },

    async getYearlyAwsCostsByServiceByProduct(
        product_id: string
    ): Promise<AwsServiceCostDbRecord[]> {
        return await apiRequest(
            `/customers/reporting/servicecostsbyyearbyproduct/${product_id}`
        );
    },

    async getAllAccountManagers(): Promise<AccountManager[]> {
        return await apiRequest('/accountmanagers');
    },

    async getAllCustomers(): Promise<Customer[]> {
        return await apiRequest('/customers');
    },

    async createCustomer(customer: Customer) {
        return await apiRequest('/customers', 'post', customer);
    },

    async updateCustomerDetail(id: string, customer: Customer) {
        return await apiRequest(`/customers/${id}`, 'put', customer);
    },

    async updateLicenseDetail(license: ProductLicense) {
        return await apiRequest(
            `/customers/${license.customerId}/licenses/${license.contractId}`,
            'put',
            license
        );
    },

    async getProductVersions(product: string): Promise<string[]> {
        return await apiRequest(
            product == 'engine' ? '/products/engine' : '/products/cc'
        );
    },

    async getAllEventsForEnv(dns: string): Promise<EventRecord[]> {
        return await apiRequest(`/events/${dns}`);
    }
};
